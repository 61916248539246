<template>
  <div class="container">
    <div class="page1" key="page1" v-if="showPage === 1">
      <div class="talk">꿈을 선택해주세요</div>

      <div class="tab-container">
        <div class="tab">
          <div :class="['tab-item', { active: activeTab === 'automatic' }]" @click="setActiveTab('automatic')">꿈 종류</div>
          <div :class="['tab-item', { active: activeTab === 'manual' }]" @click="setActiveTab('manual')">직접 입력</div>
          <div class="tab-indicator" :style="indicatorStyle"></div>
        </div>
      </div>

      <template v-if="activeTab === 'automatic'">
        <div class="main__category">
          <div
            v-for="(category, index) in mainCategories"
            :key="index"
            :value="index"
            @click="onMainCategory(index)"
            :class="{ selected: index === selectedMainCategory }"
            :style="{ animationDelay: `${index * 0.1}s` }"
          >
            {{category.icon}}
            <div class="category-name">{{ category.name }}</div>
          </div>
        </div>
        <div class="disclamer">모희또 서비스에서 제공하는 생성번호는 참고 용도이며,<br/>그로 인한 책임은 사용자에게 있습니다.</div>
      </template>

      <template v-if="activeTab === 'manual'">
        <div class="textarea-box">
          <textarea
            ref="myTextarea"
            class="custom-textarea"
            v-model="impression"
            @input="handleInput"
          />
          <div class="placeholder" @click="onPlaceholder" v-if="!impression">{{ placeholderText }}</div>
          <div class="textarea-footer">
            <span class="current">{{ impression.length }}<span class="max"> / 300</span></span>
          </div>
        </div>
      </template>
    </div>

    <div class="page2" key="page2" v-else-if="activeTab === 'automatic' && showPage === 2">
      <div class="title">
        {{mainCategories[selectedMainCategory].icon}}
        <span>{{mainCategories[selectedMainCategory].name}}</span>
      </div>
      <div class="sub__category">
        <div
          v-for="(subCategory, index) in mainCategories[selectedMainCategory].subCategories" :key="index" :value="index"
          @click="onSubCategory(index)"
          :class="{ selected: index === selectedSubCategory }"
          :style="{ animationDelay: `${index * 0.1}s` }"
        >
          {{ subCategory.name }}
          <img :src="index === selectedSubCategory ? require('@/assets/ic-system-dream-select.svg') : require('@/assets/ic-system-dream-unselect.svg')" />
        </div>
      </div>
    </div>

    <div class="page3" key="page3" v-else-if="activeTab === 'automatic' && showPage === 3">
      <div class="title">
        {{mainCategories[selectedMainCategory].subCategories[selectedSubCategory].name}}
      </div>
      <div class="sub__category">
        <div
          v-for="(item, index) in mainCategories[selectedMainCategory].subCategories[selectedSubCategory].items" :key="index"
          @click="onLastCategory(index)"
          :class="{ selected: index === selectedLastCategory }"
          :style="{ animationDelay: `${index * 0.1}s` }"
        >
          {{ item }}
          <img :src="index === selectedLastCategory ? require('@/assets/ic-system-dream-select.svg') : require('@/assets/ic-system-dream-unselect.svg')" />
        </div>
      </div>
    </div>

    <div class="floating" v-if="activeTab === 'manual'">
      <button
        class="primary"
        @click="onConfirm"
      >
        입력 완료
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

import { getCounting } from '@/utils/counting'

import { MainCategory } from '../models/Category'

@Component
export default class Dream extends Vue {
selectedMainCategory: number | null = null
selectedSubCategory: number | null = null
selectedLastCategory: number | null = null

private currentCount: number = 0
private targetCount: number = 0
private intervalId: number | null = null

private showPage: number = 1

impression: string = ''
placeholderText: string = '꿈꾸었던 꿈의 이야기를 설명해주세요.'
textareaHeight = 50
maxHeight = 300

mainCategories: MainCategory[] = [
  {
    name: '가족', // 1단계
    icon: '🤱',
    subCategories: [
      { // 2단계
        name: '부모님과 관련된 꿈',
        items: [ // 3단계
          '부모님과 함께 식사하는 꿈',
          '부모님이 아프시거나 다치는 꿈',
          '부모님이 기뻐하시는 모습을 보는 꿈',
          '부모님과 말다툼하는 꿈',
          '부모님에게 칭찬받는 꿈',
          '부모님이 새로운 소식을 전해주는 꿈',
          '부모님이 나를 도와주는 꿈',
          '부모님과 여행을 가는 꿈',
          '부모님과 함께 대화하는 꿈',
          '부모님이 집을 떠나시는 꿈'
        ]
      },
      {
        name: '형제(자매)와 관련된 꿈',
        items: [
          '형제(자매)와 함께 노는 꿈',
          '형제(자매)와 말다툼하는 꿈',
          '형제(자매)가 성공하는 꿈',
          '형제(자매)와 여행을 가는 꿈',
          '형제(자매)가 아프거나 다치는 꿈',
          '형제(자매)와 비밀을 공유하는 꿈',
          '형제(자매)가 결혼하는 꿈',
          '형제(자매)에게 도움을 받는 꿈',
          '형제(자매)와 협력해서 문제를 해결하는 꿈',
          '형제자매와 함께 웃는 꿈'
        ]
      },
      {
        name: '조부모님과 관련된 꿈',
        items: [
          '조부모님을 방문하는 꿈',
          '조부모님과 함께 대화하는 꿈',
          '조부모님이 아프신 꿈',
          '조부모님에게 선물을 받는 꿈',
          '조부모님이 돌아가시는 꿈',
          '조부모님과 함께 여행을 가는 꿈',
          '조부모님이 집을 방문하는 꿈',
          '조부모님에게 조언을 듣는 꿈',
          '조부모님과 함께 식사하는 꿈',
          '조부모님이 중요한 소식을 전하는 꿈'
        ]
      },
      {
        name: '자녀와 관련된 꿈',
        items: [
          '자녀가 학교에 가는 꿈',
          '자녀가 성공하는 꿈',
          '자녀가 아프거나 다치는 꿈',
          '자녀가 결혼하는 꿈',
          '자녀와 함께 노는 꿈',
          '자녀에게 선물을 주는 꿈',
          '자녀가 공부하는 꿈',
          '자녀가 다른 나라로 떠나는 꿈',
          '자녀가 집을 떠나는 꿈',
          '자녀가 말을 잘 듣는 꿈'
        ]
      },
      {
        name: '사촌과 관련된 꿈',
        items: [
          '사촌과 함께 시간을 보내는 꿈',
          '사촌과 다투는 꿈',
          '사촌이 결혼하는 꿈',
          '사촌이 성공하는 꿈',
          '사촌과 함께 여행을 가는 꿈',
          '사촌이 아프거나 다치는 꿈',
          '사촌과 함께 웃는 꿈',
          '사촌과 비밀을 나누는 꿈',
          '사촌이 집을 방문하는 꿈',
          '사촌과의 관계가 깊어지는 꿈'
        ]
      },
      {
        name: '이모(삼촌)과 관련된 꿈',
        items: [
          '이모(삼촌)이 집에 방문하는 꿈',
          '이모(삼촌)과 함께 시간을 보내는 꿈',
          '이모/삼촌과 함께 여행을 가는 꿈',
          '이모/삼촌에게 선물을 받는 꿈',
          '이모/삼촌이 아프거나 다치는 꿈',
          '이모/삼촌과 다투는 꿈',
          '이모/삼촌과 대화하는 꿈',
          '이모/삼촌과 함께 식사하는 꿈',
          '이모/삼촌에게 중요한 조언을 받는 꿈',
          '이모/삼촌과 함께 사진을 찍는 꿈'
        ]
      },
      {
        name: '배우자와 관련된 꿈',
        items: [
          '배우자와 다투는 꿈',
          '배우자와 함께 여행을 가는 꿈',
          '배우자에게 선물을 받는 꿈',
          '배우자가 아프거나 다치는 꿈',
          '배우자와 함께 웃는 꿈',
          '배우자와 미래를 계획하는 꿈',
          '배우자와 함께 식사하는 꿈',
          '배우자와의 관계가 나빠지는 꿈',
          '배우자와 화해하는 꿈',
          '배우자와 중요한 결정을 내리는 꿈'
        ]
      },
      {
        name: '친척과 관련된 꿈',
        items: [
          '친척이 결혼하는 꿈',
          '친척과 함께 시간을 보내는 꿈',
          '친척이 아프거나 다치는 꿈',
          '친척과 다투는 꿈',
          '친척과 함께 식사하는 꿈',
          '친척과 함께 중요한 일을 하는 꿈',
          '친척에게 도움을 받는 꿈',
          '친척이 집을 방문하는 꿈',
          '친척과의 관계가 좋아지는 꿈',
          '친척과 함께 여행을 떠나는 '
        ]
      },
      {
        name: '가족의 결혼과 관련된 꿈',
        items: [
          '형제/자매가 결혼하는 꿈',
          '부모님의 결혼 기념일을 축하하는 꿈',
          '자녀의 결혼식을 준비하는 꿈',
          '가족과 결혼식에 참석하는 꿈',
          '친척의 결혼식을 축하하는 꿈',
          '가족 결혼식을 위해 준비하는 꿈',
          '결혼식에서 가족과 함께하는 꿈',
          '가족이 결혼식에서 다투는 꿈',
          '가족 결혼식에서 축하하는 꿈',
          '가족의 결혼 소식을 듣는 꿈 '
        ]
      },
     {
        name: '가족과의 특별한 행사와 관련된 꿈',
        items: [
          '가족과 명절을 보내는 꿈',
          '가족과 함께 생일을 축하하는 꿈',
          '가족과 함께 휴가를 떠나는 꿈',
          '가족과 결혼식에 참석하는 꿈',
          '가족과 함께 특별한 날을 기념하는 꿈',
          '가족 행사에서 다투는 꿈',
          '가족과 함께 축제를 즐기는 꿈',
          '가족이 함께 모이는 꿈',
          '가족과 함께 기념 사진을 찍는 꿈',
          '가족과 함께 교회나 종교 행사를 하는 꿈 '
        ]
      },
    ]
  },
  {
    name: '재물',
    icon: '🤑',
    subCategories: [
      {
        name: '돈을 버는 꿈',
        items: [
          '사업으로 큰 돈을 버는 꿈',
          '친구에게 돈을 빌려주는 꿈',
          '주식으로 수익을 얻는 꿈',
          '큰 계약을 따내 돈을 버는 꿈',
          '은행에서 돈을 인출하는 꿈',
          '돈이 쌓이는 것을 보는 꿈',
          '경품이나 상금을 받는 꿈',
          '부모님에게 돈을 받는 꿈',
          '상사에게 보너스를 받는 꿈',
          '갑자기 큰 돈을 줍는 꿈'
        ]
      },
      {
        name: '돈을 잃는 꿈',
        items: [
          '지갑을 잃어버리는 꿈',
          '돈이 든 가방을 잃는 꿈',
          '주머니에서 돈이 사라지는 꿈',
          '친구에게 돈을 빌려주고 돌려받지 못하는 꿈',
          '돈을 투자하고 잃는 꿈',
          '도둑이 돈을 훔쳐가는 꿈',
          '돈을 잘못 계산해서 손해를 보는 꿈',
          '길에서 돈을 떨어뜨리는 꿈',
          '카지노에서 돈을 잃는 꿈',
          '중요한 돈을 도난당하는 꿈'
        ]
      },
      {
        name: '재산을 얻는 꿈',
        items: [
          '유산을 상속받는 꿈',
          '부동산을 구매하는 꿈',
          '큰 집을 선물 받는 꿈',
          '부자가 되는 꿈',
          '귀중한 물건을 얻는 꿈',
          '상속받은 돈으로 재산을 늘리는 꿈',
          '금전적으로 큰 성공을 거두는 꿈',
          '땅이나 부동산을 증여받는 꿈',
          '갑작스럽게 많은 재산을 받는 꿈',
          '귀중한 재산을 구매하는 꿈'
        ]
      },
      {
        name: '보물을 찾는 꿈',
        items: [
          '해변에서 보물을 발견하는 꿈',
          '보물 상자를 여는 꿈',
          '숨겨진 금화를 찾는 꿈',
          '보물을 감추는 꿈',
          '보물 지도를 따라가는 꿈',
          '오래된 보물을 발굴하는 꿈',
          '보물 창고를 발견하는 꿈',
          '고대 유적에서 보물을 찾는 꿈',
          '보물을 팔아 돈을 버는 꿈',
          '보물을 안전하게 지키는 꿈'
        ]
      },
      {
        name: '비싼 물건을 사는 꿈',
        items: [
          '고급 자동차를 사는 꿈',
          '비싼 보석을 사는 꿈',
          '명품 가방을 구매하는 꿈',
          '고가의 시계를 사는 꿈',
          '고급 가구를 구매하는 꿈',
          '고급 아파트를 사는 꿈',
          '귀중한 그림을 구매하는 꿈',
          '고급 휴가 패키지를 사는 꿈',
          '고급 식사를 위해 돈을 쓰는 꿈',
          '경매에서 비싼 물건을 사는 꿈'
        ]
      },
      {
        name: '복권에 당첨되는 꿈',
        items: [
          '복권에 당첨되어 크게 기뻐하는 꿈',
          '로또 번호를 맞추는 꿈',
          '복권 당첨 소식을 듣는 꿈',
          '친구와 복권 당첨금을 나누는 꿈',
          '복권을 사는 꿈',
          '복권 당첨 후 생활이 바뀌는 꿈',
          '복권으로 큰 돈을 받는 꿈',
          '당첨 후 가족에게 돈을 나누어주는 꿈',
          '복권에 당첨되지 않는 꿈',
          '복권을 잃어버리는 꿈'
        ]
      },
      {
        name: '물건을 도난당하는 꿈',
        items: [
          '소중한 물건이 사라지는 꿈',
          '차를 도난당하는 꿈',
          '지갑을 도둑맞는 꿈',
          '집에 도둑이 들어오는 꿈',
          '귀중한 보석을 도난당하는 꿈',
          '친구에게 물건을 도난당하는 꿈',
          '중요한 서류가 사라지는 꿈',
          '중요한 물건을 도둑맞는 꿈',
          '도난당한 물건을 다시 찾는 꿈',
          '도둑을 잡으려는 꿈'
        ]
      },
      {
        name: '투자에 성공하는 꿈',
        items: [
          '주식으로 큰 수익을 얻는 꿈',
          '부동산 투자에 성공하는 꿈',
          '사업에 투자하여 성공하는 꿈',
          '투자로 이익을 보는 꿈',
          '투자 제안을 받는 꿈',
          '새로운 비즈니스에 투자하는 꿈',
          '친구와 함께 투자하는 꿈',
          '투자로 부자가 되는 꿈',
          '투자를 통해 자산을 늘리는 꿈',
          '투자로 인해 성공을 축하받는 꿈'
        ]
      },
      {
        name: '채무와 관련된 꿈',
        items: [
          '친구에게 빚을 갚는 꿈',
          '은행에서 대출을 받는 꿈',
          '채권자가 찾아오는 꿈',
          '빚을 갚지 못해 걱정하는 꿈',
          '빚을 청산하는 꿈',
          '친구에게 돈을 빌리는 꿈',
          '가족에게 돈을 빌리는 꿈',
          '빚을 해결하고 안심하는 꿈',
          '빚을 갚기 위해 노력하는 꿈',
          '빚이 사라지는 꿈'
        ]
      },
      {
        name: '금과 보석과 관련된 꿈',
        items: [
          '금을 발견하는 꿈',
          '보석을 손에 넣는 꿈',
          '금고에 금과 보석을 보관하는 꿈',
          '금반지를 사는 꿈',
          '다이아몬드를 선물 받는 꿈',
          '보석을 감정받는 꿈',
          '금화로 거래하는 꿈',
          '금을 팔아서 돈을 버는 꿈',
          '보석을 도둑맞는 꿈',
          '금 목걸이를 사는 꿈'
        ]
      }

      ]
    },
    {
      name: '건강',
      icon: '💪',
      subCategories: [
        {
          name: '병에서 회복하는 꿈',
          items: [
            '병이 완쾌되는 꿈',
            '회복 중에 축하받는 꿈',
            '병상에서 일어나는 꿈',
            '약을 먹고 병이 낫는 꿈',
            '가족이 건강을 되찾는 꿈',
            '오랜 병에서 회복하는 꿈',
            '치료 후 건강해지는 꿈',
            '자신의 몸이 정상으로 돌아오는 꿈',
            '친구가 회복하는 꿈',
            '건강 검진 결과가 좋은 꿈'
          ]
        },
        {
          name: '아픈 사람과 관련된 꿈',
          items: [
            '누군가가 아프다는 소식을 듣는 꿈',
            '가족이 병에 걸리는 꿈',
            '친구가 병문안을 받는 꿈',
            '연인이 병에 걸리는 꿈',
            '동료가 건강이 나빠지는 꿈',
            '병원에서 아픈 사람을 돌보는 꿈',
            '아픈 사람을 간호하는 꿈',
            '아픈 사람과 대화하는 꿈',
            '아픈 사람이 회복하는 꿈',
            '아픈 사람을 위해 기도하는 꿈'
          ]
        },
        {
          name: '건강검진을 받는 꿈',
          items: [
            '병원에서 건강 검사를 받는 꿈',
            '의사에게 결과를 듣는 꿈',
            '검진 중 문제가 발견되는 꿈',
            '검진 결과가 좋은 꿈',
            '검진 결과를 기다리는 꿈',
            '혈액 검사를 받는 꿈',
            '정기 검진을 예약하는 꿈',
            '검진 중 병이 발견되는 꿈',
            '검진 결과에 놀라는 꿈',
            '검진 후 의사와 상담하는 꿈'
          ]
        },
        {
          name: '의사를 만나는 꿈',
          items: [
            '의사에게 진료를 받는 꿈',
            '의사와 건강 상태에 대해 대화하는 꿈',
            '의사에게 치료를 받는 꿈',
            '의사가 병을 진단하는 꿈',
            '의사와 함께 건강 관리를 논의하는 꿈',
            '새로운 의사를 만나는 꿈',
            '의사가 약을 처방하는 꿈',
            '의사와 수술에 대해 논의하는 꿈',
            '의사와 건강 관련 상담을 받는 꿈',
            '의사가 건강 회복을 축하하는 꿈'
          ]
        },
        {
          name: '운동을 하는 꿈',
          items: [
            '체육관에서 운동하는 꿈',
            '집에서 스트레칭하는 꿈',
            '달리기를 하는 꿈',
            '요가를 하는 꿈',
            '수영장에서 수영하는 꿈',
            '헬스장에서 웨이트 트레이닝을 하는 꿈',
            '등산을 하는 꿈',
            '운동 중 부상을 당하는 꿈',
            '운동을 통해 체력을 키우는 꿈',
            '친구와 함께 운동하는 꿈'
          ]
        },
        {
          name: '부상을 입는 꿈',
          items: [
            '넘어져 다치는 꿈',
            '발목을 삐는 꿈',
            '사고로 다리를 다치는 꿈',
            '손을 베이는 꿈',
            '부상을 당하고 응급 치료를 받는 꿈',
            '친구가 부상당하는 꿈',
            '부상 후 깁스를 하는 꿈',
            '운동 중 부상을 당하는 꿈',
            '머리를 다치는 꿈',
            '부상 후 회복하는 꿈'
          ]
        },
        {
          name: '약을 복용하는 꿈',
          items: [
            '의사에게 약을 처방받는 꿈',
            '약을 먹고 건강이 좋아지는 꿈',
            '약을 복용하지만 효과가 없는 꿈',
            '비타민을 복용하는 꿈',
            '가족에게 약을 건네는 꿈',
            '약을 잘못 복용하는 꿈',
            '약이 없어서 걱정하는 꿈',
            '약 복용 후 부작용을 느끼는 꿈',
            '약국에서 약을 사는 꿈',
            '약을 먹고 잠을 자는 꿈'
          ]
        },
        {
          name: '병원에서 치료받는 꿈',
          items: [
            '병원에 입원하는 꿈',
            '수술을 받는 꿈',
            '응급실에서 치료받는 꿈',
            '간호사에게 치료받는 꿈',
            '병실에서 머무는 꿈',
            '병원에서 진료받는 꿈',
            '병원에서 퇴원하는 꿈',
            '병원에서 회복하는 꿈',
            '병원에서 약을 처방받는 꿈',
            '병원에서 의사와 상담하는 꿈'
          ]
        },
        {
          name: '질병과 싸우는 꿈',
          items: [
            '암과 싸우는 꿈',
            '전염병에 걸리는 꿈',
            '알레르기 증상을 겪는 꿈',
            '두통을 느끼는 꿈',
            '피로로 인해 힘들어하는 꿈',
            '몸이 마비되는 꿈',
            '만성질환을 앓는 꿈',
            '치료를 받지만 나아지지 않는 꿈',
            '병이 재발하는 꿈',
            '질병을 극복하는 꿈'
          ]
        },
        {
          name: '정신 건강과 관련된 꿈',
          items: [
            '스트레스를 해소하는 꿈',
            '정신적으로 불안한 꿈',
            '우울함을 느끼는 꿈',
            '명상을 통해 안정감을 느끼는 꿈',
            '심리 상담을 받는 꿈',
            '불면증에 시달리는 꿈',
            '공포증과 관련된 꿈',
            '마음이 편안해지는 꿈',
            '긴장을 풀고 휴식을 취하는 꿈',
            '정신적으로 혼란스러운 꿈'
          ]
        }

      ]
    },
    {
      name: '직업',
      icon: '👨🏻‍🌾',
      subCategories: [
        {
          name: '새로운 직업을 얻는 꿈',
          items: [
            '새로운 직장에 합격하는 꿈',
            '면접을 성공적으로 보는 꿈',
            '첫 출근을 하는 꿈',
            '새로운 동료를 만나는 꿈',
            '직장에서 적응하는 꿈',
            '이직을 결정하는 꿈',
            '새로운 업무를 맡는 꿈',
            '새로운 직업에 대한 설렘을 느끼는 꿈',
            '직장에서 처음으로 업무를 수행하는 꿈',
            '새로운 직장에서 승진을 기대하는 꿈'
          ]
        },
        {
          name: '직장에서의 성공 관련 꿈',
          items: [
            '프로젝트를 성공적으로 마무리하는 꿈',
            '상사로부터 칭찬받는 꿈',
            '성과에 대한 보너스를 받는 꿈',
            '고객에게 긍정적인 피드백을 받는 꿈',
            '회사의 중요한 계약을 따내는 꿈',
            '성과로 인해 승진하는 꿈',
            '사내 회의에서 발표를 성공적으로 하는 꿈',
            '팀원들과 함께 성과를 나누는 꿈',
            '직장에서 성과를 인정받는 꿈',
            '회사 내에서 리더 역할을 맡는 꿈'
          ]
        },
        {
          name: '동료와 관련된 꿈',
          items: [
            '동료와 함께 협력하는 꿈',
            '동료와 다투는 꿈',
            '동료와 화해하는 꿈',
            '동료와 함께 식사하는 꿈',
            '동료와 새로운 프로젝트를 시작하는 꿈',
            '동료가 나를 돕는 꿈',
            '동료와 친해지는 꿈',
            '동료의 승진을 축하하는 꿈',
            '동료가 떠나는 꿈',
            '동료와 사무실에서 웃고 대화하는 꿈'
          ]
        },
        {
          name: '상사와 관련된 꿈',
          items: [
            '상사에게 칭찬받는 꿈',
            '상사와 대화를 나누는 꿈',
            '상사로부터 질책을 받는 꿈',
            '상사가 승진을 제안하는 꿈',
            '상사와 함께 프로젝트를 하는 꿈',
            '상사와 회의에서 논의하는 꿈',
            '상사가 나에게 업무를 맡기는 꿈',
            '상사와 개인적인 대화를 나누는 꿈',
            '상사로부터 업무에 대한 피드백을 받는 꿈',
            '상사에게 중요한 결정을 전달받는 꿈'
          ]
        },
        {
          name: '직장 내 갈등 관련 꿈',
          items: [
            '동료와 갈등을 겪는 꿈',
            '상사와 의견 충돌이 있는 꿈',
            '직장 내에서 따돌림을 당하는 꿈',
            '업무에서 큰 실수를 저지르는 꿈',
            '회의 중 논쟁을 벌이는 꿈',
            '직장에서 경쟁자로부터 도전을 받는 꿈',
            '프로젝트에서 의견 충돌이 발생하는 꿈',
            '상사에게 꾸중을 듣는 꿈',
            '직장에서 스트레스를 받는 꿈',
            '직장 내에서 불편한 상황을 경험하는 꿈'
          ]
        },
        {
          name: '해고나 직장을 잃는 꿈',
          items: [
            '해고 통보를 받는 꿈',
            '일자리를 잃는 꿈',
            '해고 후 불안감을 느끼는 꿈',
            '직장에서 마지막으로 인사하는 꿈',
            '직장을 잃고 새로운 직장을 찾는 꿈',
            '동료가 해고되는 꿈',
            '회사에서 일자리를 잃게 되는 꿈',
            '해고로 인해 걱정하는 꿈',
            '해고 후 직업 상담을 받는 꿈',
            '해고 후 가족과 상의하는 꿈'
          ]
        },
        {
          name: '업무에 성공하는 꿈',
          items: [
            '중요한 업무를 마치는 꿈',
            '프로젝트를 성공적으로 마무리하는 꿈',
            '상사에게 인정받는 꿈',
            '고객으로부터 긍정적인 평가를 받는 꿈',
            '성과를 내고 축하받는 꿈',
            '회사에서 중요한 업무를 맡는 꿈',
            '팀워크로 성공을 이루는 꿈',
            '업무 성과로 승진하는 꿈',
            '업무 중 중요한 결정을 내리는 꿈',
            '업무에서 스트레스를 이겨내는 꿈'
          ]
        },
        {
          name: '직장에서 승진하는 꿈',
          items: [
            '상사로부터 승진 제안을 받는 꿈',
            '승진을 축하받는 꿈',
            '승진 후 새로운 업무를 맡는 꿈',
            '승진 후 새로운 직책을 수행하는 꿈',
            '승진 소식을 가족과 나누는 꿈',
            '동료들이 나의 승진을 축하하는 꿈',
            '승진 후 팀을 이끄는 꿈',
            '승진 후 보너스를 받는 꿈',
            '승진 후 새로운 사무실로 이동하는 꿈',
            '승진 후 상사와 축하하는 꿈'
          ]
        },
        {
          name: '새로운 프로젝트를 맡는 꿈',
          items: [
            '새로운 프로젝트를 시작하는 꿈',
            '프로젝트에서 팀을 이끄는 꿈',
            '프로젝트 마감일을 맞추는 꿈',
            '프로젝트 성공을 축하받는 꿈',
            '프로젝트에서 문제가 발생하는 꿈',
            '프로젝트 중 상사와 회의하는 꿈',
            '프로젝트를 위해 새로운 도전을 하는 꿈',
            '프로젝트를 기획하는 꿈',
            '프로젝트 진행 상황을 보고하는 꿈',
            '프로젝트에서 목표를 달성하는 꿈'
          ]
        },
        {
          name: '직업적 변화를 경험하는 꿈',
          items: [
            '새로운 직업을 선택하는 꿈',
            '직업을 바꾸는 결정을 내리는 꿈',
            '이직을 고민하는 꿈',
            '직업 변화를 가족과 상의하는 꿈',
            '새로운 분야에서 일하는 꿈',
            '새로운 직장에서 적응하는 꿈',
            '직업적 변화를 통해 성장하는 꿈',
            '직업 변화를 통해 성공하는 꿈',
            '새로운 직업에서 동료를 만나는 꿈',
            '직업 변화를 통해 만족감을 느끼는 꿈'
          ]
        }
      ]
    },
    {
      name: '여행',
      icon: '🏄‍',
      subCategories: [
        {
          name: '해외 여행을 떠나는 꿈',
          items: [
            '비행기를 타고 해외로 떠나는 꿈',
            '외국에서 낯선 문화를 경험하는 꿈',
            '해외에서 길을 잃는 꿈',
            '유명 관광지를 방문하는 꿈',
            '외국에서 새로운 사람을 만나는 꿈',
            '해외에서 호텔에 머무는 꿈',
            '외국 음식을 먹는 꿈',
            '외국 언어를 사용하는 꿈',
            '외국 쇼핑몰에서 물건을 사는 꿈',
            '낯선 외국 도시에 적응하는 꿈'
          ]
        },
        {
          name: '국내 여행을 떠나는 꿈',
          items: [
            '국내 유명 관광지에 가는 꿈',
            '국내 여행 중 산을 오르는 꿈',
            '기차를 타고 국내 여행하는 꿈',
            '국내 해변에서 휴식을 취하는 꿈',
            '국내 명소에서 사진을 찍는 꿈',
            '국내 여행 중 길을 잃는 꿈',
            '국내 여행을 친구와 계획하는 꿈',
            '국내 도시를 탐험하는 꿈',
            '국내 음식점을 방문하는 꿈',
            '버스를 타고 국내 여행하는 꿈'
          ]
        },
        {
          name: '친구와 함께 여행하는 꿈',
          items: [
            '친구와 여행을 계획하는 꿈',
            '친구와 함께 비행기를 타는 꿈',
            '친구와 해외여행을 떠나는 꿈',
            '친구와 호텔에 머무는 꿈',
            '친구와 함께 관광지를 방문하는 꿈',
            '친구와 길을 잃는 꿈',
            '친구와 산을 오르는 꿈',
            '친구와 함께 사진을 찍는 꿈',
            '친구와 모험을 즐기는 꿈',
            '친구와 해변에서 시간을 보내는 꿈'
          ]
        },
        {
          name: '가족과 함께 여행하는 꿈',
          items: [
            '가족과 여행을 계획하는 꿈',
            '가족과 함께 해외로 떠나는 꿈',
            '가족과 국내 유명 관광지를 방문하는 꿈',
            '가족과 해변에서 휴식을 취하는 꿈',
            '가족과 여행 중 식사를 하는 꿈',
            '가족과 함께 호텔에 머무는 꿈',
            '가족과 캠핑을 하는 꿈',
            '가족과 여행 중 문제가 발생하는 꿈',
            '가족과 사진을 찍는 꿈',
            '가족과 길을 잃는 꿈'
          ]
        },
        {
          name: '비행기와 관련된 꿈',
          items: [
            '비행기를 타고 여행하는 꿈',
            '비행기에서 자리를 찾는 꿈',
            '비행기에서 음식을 먹는 꿈',
            '비행기에서 기내 방송을 듣는 꿈',
            '비행기 착륙을 기다리는 꿈',
            '비행기에서 창밖을 보는 꿈',
            '비행기 안에서 잠을 자는 꿈',
            '비행기에서 친구와 대화하는 꿈',
            '비행기가 연착되는 꿈',
            '비행기에서 짐을 분실하는 꿈'
          ]
        },
        {
          name: '차로 여행하는 꿈',
          items: [
            '자동차를 타고 도로 여행을 떠나는 꿈',
            '차를 직접 운전하는 꿈',
            '차로 국도를 달리는 꿈',
            '차에서 음악을 듣는 꿈',
            '차를 타고 여행 중 길을 잃는 꿈',
            '차를 타고 산길을 오르는 꿈',
            '차가 고장 나는 꿈',
            '차에서 가족과 함께 시간을 보내는 꿈',
            '차로 야경을 감상하는 꿈',
            '차를 세우고 쉬는 꿈'
          ]
        },
        {
          name: '여행 중 길을 잃는 꿈',
          items: [
            '해외에서 길을 잃는 꿈',
            '도시에 도착했지만 길을 잃는 꿈',
            '지도를 보면서도 길을 찾지 못하는 꿈',
            '여행지에서 혼자가 되는 꿈',
            '여행 중 낯선 사람에게 길을 묻는 꿈',
            '길을 잃고 불안해하는 꿈',
            '모르는 장소에서 길을 찾는 꿈',
            '길을 잃고 방황하는 꿈',
            '가족과 함께 길을 잃는 꿈',
            '친구와 함께 길을 잃는 꿈'
          ]
        },
        {
          name: '여행 중 사고를 당하는 꿈',
          items: [
            '여행 중 교통사고를 당하는 꿈',
            '여행 중 배에서 사고를 당하는 꿈',
            '비행기 사고를 경험하는 꿈',
            '호텔에서 사고가 발생하는 꿈',
            '여행 중 다리를 다치는 꿈',
            '여행 중 가방을 잃어버리는 꿈',
            '여행 중 폭풍우를 만나는 꿈',
            '여행지에서 화재를 경험하는 꿈',
            '여행 중 길을 잃고 사고를 당하는 꿈',
            '여행 중 차가 고장 나는 꿈'
          ]
        },
        {
          name: '관광지에서 즐기는 꿈',
          items: [
            '유명 관광지를 방문하는 꿈',
            '관광지에서 사진을 찍는 꿈',
            '관광 명소에서 기념품을 사는 꿈',
            '박물관을 방문하는 꿈',
            '놀이공원을 즐기는 꿈',
            '역사적인 장소를 방문하는 꿈',
            '관광지에서 가이드를 만나는 꿈',
            '관광지에서 음식 체험을 하는 꿈',
            '관광지에서 친구와 즐거운 시간을 보내는 꿈',
            '관광지에서 길을 잃는 꿈'
          ]
        },
        {
          name: '여행 준비를 하는 꿈',
          items: [
            '여행 가방을 싸는 꿈',
            '여권을 준비하는 꿈',
            '여행 일정을 짜는 꿈',
            '항공권을 예약하는 꿈',
            '숙소를 예약하는 꿈',
            '여행 가방을 잃어버리는 꿈',
            '여행 준비 중 문제가 발생하는 꿈',
            '여행 가이드북을 읽는 꿈',
            '친구와 여행 준비를 하는 꿈',
            '여행 준비에 설레는 꿈'
          ]
        }
      ]
    },
    {
      name: '동물',
      icon: '🐽',
      subCategories: [
        {
          name: '개와 관련된 꿈',
          items: [
            '개와 산책하는 꿈',
            '개가 짖는 꿈',
            '개가 주인을 보호하는 꿈',
            '개에게 물리는 꿈',
            '개를 잃어버리는 꿈',
            '개를 훈련시키는 꿈',
            '길 잃은 개를 발견하는 꿈',
            '개와 놀아주는 꿈',
            '개가 도망가는 꿈',
            '여러 마리의 개를 보는 꿈'
          ]
        },
        {
          name: '고양이와 관련된 꿈',
          items: [
            '고양이를 쓰다듬는 꿈',
            '고양이가 야옹하는 꿈',
            '고양이가 쥐를 잡는 꿈',
            '고양이가 높은 곳에 올라가는 꿈',
            '검은 고양이를 보는 꿈',
            '고양이가 다투는 꿈',
            '고양이가 도망가는 꿈',
            '고양이가 새끼를 낳는 꿈',
            '고양이를 집으로 데려오는 꿈',
            '고양이가 다치는 꿈'
          ]
        },
        {
          name: '새와 관련된 꿈',
          items: [
            '새가 날아다니는 꿈',
            '새가 울부짖는 꿈',
            '새장을 탈출하는 꿈',
            '새가 나무에 앉아 있는 꿈',
            '새가 모이를 찾는 꿈',
            '알에서 새가 부화하는 꿈',
            '새가 다른 새와 싸우는 꿈',
            '새가 집을 떠나는 꿈',
            '새가 창문으로 들어오는 꿈',
            '새가 떨어지는 꿈'
          ]
        },
        {
          name: '물고기와 관련된 꿈',
          items: [
            '물고기를 잡는 꿈',
            '물고기가 헤엄치는 꿈',
            '물고기를 먹는 꿈',
            '물고기가 죽는 꿈',
            '물고기가 알을 낳는 꿈',
            '큰 물고기를 보는 꿈',
            '물고기 떼를 만나는 꿈',
            '물고기가 도망가는 꿈',
            '물고기와 수영하는 꿈',
            '물고기를 놓치는 꿈'
          ]
        },
        {
          name: '사자와 관련된 꿈',
          items: [
            '사자가 울부짖는 꿈',
            '사자가 나타나는 꿈',
            '사자가 공격하는 꿈',
            '사자와 싸우는 꿈',
            '사자가 새끼를 돌보는 꿈',
            '사자가 풀밭을 거니는 꿈',
            '사자가 다른 동물과 싸우는 꿈',
            '사자 무리를 보는 꿈',
            '사자가 사람을 지켜주는 꿈',
            '사자를 타는 꿈'
          ]
        },
        {
          name: '뱀과 관련된 꿈',
          items: [
            '뱀에게 물리는 꿈',
            '뱀이 꿈틀거리는 꿈',
            '뱀을 잡는 꿈',
            '뱀을 쫓아내는 꿈',
            '뱀에게 쫓기는 꿈',
            '뱀이 다른 동물을 먹는 꿈',
            '뱀이 피부를 벗는 꿈',
            '여러 마리의 뱀이 나타나는 꿈',
            '뱀이 갑자기 나타나는 꿈',
            '뱀이 방 안에 있는 꿈'
          ]
        },
        {
          name: '야생 동물과 관련된 꿈',
          items: [
            '야생 동물을 구경하는 꿈',
            '야생 동물에게 쫓기는 꿈',
            '야생 동물에게 먹이를 주는 꿈',
            '야생 동물과 싸우는 꿈',
            '야생 동물이 새끼를 돌보는 꿈',
            '숲 속에서 야생 동물을 만나는 꿈',
            '야생 동물이 집에 들어오는 꿈',
            '사파리에서 야생 동물을 보는 꿈',
            '동물원에서 야생 동물을 보는 꿈',
            '야생 동물이 나를 공격하는 꿈'
          ]
        },
        {
          name: '애완동물을 키우는 꿈',
          items: [
            '애완동물과 함께 놀아주는 꿈',
            '애완동물에게 먹이를 주는 꿈',
            '애완동물을 산책시키는 꿈',
            '애완동물을 목욕시키는 꿈',
            '애완동물과 함께 잠을 자는 꿈',
            '새 애완동물을 입양하는 꿈',
            '애완동물이 아픈 꿈',
            '애완동물이 도망가는 꿈',
            '애완동물과 함께 병원에 가는 꿈',
            '애완동물을 돌보는 꿈'
          ]
        },
        {
          name: '동물과 상호작용하는 꿈',
          items: [
            '동물을 쓰다듬는 꿈',
            '동물과 대화하는 꿈',
            '동물에게 먹이를 주는 꿈',
            '동물과 함께 걷는 꿈',
            '동물이 나를 따라오는 꿈',
            '동물과 장난치는 꿈',
            '동물에게 길을 안내받는 꿈',
            '동물이 나를 보호하는 꿈',
            '동물과 함께 시간을 보내는 꿈',
            '동물에게 도움을 받는 꿈'
          ]
        },
        {
          name: '동물에게 쫓기는 꿈',
          items: [
            '사나운 개에게 쫓기는 꿈',
            '사자에게 쫓기는 꿈',
            '곰에게 쫓기는 꿈',
            '뱀에게 쫓기는 꿈',
            '고양이에게 쫓기는 꿈',
            '큰 새에게 쫓기는 꿈',
            '야생 동물에게 쫓기는 꿈',
            '늑대에게 쫓기는 꿈',
            '코끼리에게 쫓기는 꿈',
            '원숭이에게 쫓기는 꿈'
          ]
        }

      ]
    },
    {
      name: '자연',
      icon: '🤱',
      subCategories: [
        {
          name: '비와 관련된 꿈',
          items: [
            '폭우가 내리는 꿈',
            '가랑비가 내리는 꿈',
            '비를 맞으며 걷는 꿈',
            '비를 피하는 꿈',
            '비가 그치는 꿈',
            '천둥과 비가 같이 내리는 꿈',
            '비를 맞고 춥게 느끼는 꿈',
            '비와 바람이 동시에 부는 꿈',
            '비가 오면서 꽃이 피는 꿈',
            '비가 멈추고 해가 나는 꿈'
          ]
        },
        {
          name: '눈과 관련된 꿈',
          items: [
            '눈이 펑펑 내리는 꿈',
            '눈싸움을 하는 꿈',
            '눈 덮인 산을 오르는 꿈',
            '눈이 녹아내리는 꿈',
            '눈길을 걷는 꿈',
            '눈을 맞으며 춥게 느끼는 꿈',
            '눈 속에서 길을 잃는 꿈',
            '눈사람을 만드는 꿈',
            '눈이 강하게 내리는 폭설 꿈',
            '햇빛에 눈이 녹는 꿈'
          ]
        },
        {
          name: '산과 관련된 꿈',
          items: [
            '산을 오르는 꿈',
            '산에서 길을 잃는 꿈',
            '산 정상에 오르는 꿈',
            '산 아래로 내려오는 꿈',
            '산에서 캠핑하는 꿈',
            '눈 덮인 산을 바라보는 꿈',
            '산속에서 동물을 만나는 꿈',
            '산길을 헤매는 꿈',
            '산속 폭포를 발견하는 꿈',
            '산에서 나무가 우거진 숲을 걷는 꿈'
          ]
        },
        {
          name: '바다와 관련된 꿈',
          items: [
            '맑은 바다를 보는 꿈',
            '바다에서 수영하는 꿈',
            '파도가 치는 바다를 보는 꿈',
            '해변에서 산책하는 꿈',
            '바다에서 배를 타는 꿈',
            '바다에서 낚시하는 꿈',
            '바닷물이 넘쳐나는 꿈',
            '깊은 바다 속으로 들어가는 꿈',
            '태풍이 바다에 몰아치는 꿈',
            '바다에 해가 지는 모습을 보는 꿈'
          ]
        },
        {
          name: '폭풍과 관련된 꿈',
          items: [
            '강풍이 몰아치는 꿈',
            '폭우가 내리는 꿈',
            '천둥과 번개가 치는 꿈',
            '폭풍우 속에서 길을 잃는 꿈',
            '집이 폭풍에 흔들리는 꿈',
            '바람이 매우 강하게 부는 꿈',
            '폭풍 속에서 나무가 쓰러지는 꿈',
            '폭풍이 지나가고 맑은 하늘을 보는 꿈',
            '폭풍 속에서 배를 타는 꿈',
            '창문이 폭풍에 깨지는 꿈'
          ]
        },
        {
          name: '강과 관련된 꿈',
          items: [
            '잔잔한 강물을 보는 꿈',
            '강에서 수영하는 꿈',
            '강에서 배를 타는 꿈',
            '강물이 넘쳐 흐르는 꿈',
            '강에서 물고기를 잡는 꿈',
            '강을 건너는 꿈',
            '강가에서 산책하는 꿈',
            '강에서 다리를 보는 꿈',
            '폭포가 있는 강을 보는 꿈',
            '강물이 매우 흐르는 꿈'
          ]
        },
        {
          name: '숲과 관련된 꿈',
          items: [
            '울창한 숲을 걷는 꿈',
            '숲 속에서 길을 잃는 꿈',
            '숲 속에서 동물을 만나는 꿈',
            '숲 속에서 캠핑하는 꿈',
            '숲 속에서 폭포를 발견하는 꿈',
            '숲에서 나무를 베는 꿈',
            '숲에서 맑은 공기를 마시는 꿈',
            '숲 속에서 위험을 피하는 꿈',
            '숲에서 모험하는 꿈',
            '숲 속에서 비를 피하는 꿈'
          ]
        },
        {
          name: '무지개와 관련된 꿈',
          items: [
            '무지개를 보는 꿈',
            '하늘에 선명한 무지개가 뜨는 꿈',
            '비 온 뒤 무지개가 나타나는 꿈',
            '이중 무지개를 보는 꿈',
            '산 너머에 무지개가 걸리는 꿈',
            '바다 위에 무지개가 떠 있는 꿈',
            '무지개를 잡으려 하는 꿈',
            '무지개 끝을 찾는 꿈',
            '무지개 아래서 서 있는 꿈',
            '무지개 색이 희미해지는 꿈'
          ]
        },
        {
          name: '하늘과 관련된 꿈',
          items: [
            '맑은 하늘을 보는 꿈',
            '구름이 많은 하늘을 보는 꿈',
            '저녁 하늘의 노을을 보는 꿈',
            '별이 빛나는 하늘을 보는 꿈',
            '하늘을 나는 꿈',
            '하늘이 붉게 변하는 꿈',
            '하늘에서 무언가가 떨어지는 꿈',
            '구름 위를 걷는 꿈',
            '하늘에서 빛이 나는 꿈',
            '하늘을 바라보며 평화를 느끼는 꿈'
          ]
        },
        {
          name: '자연재해와 관련된 꿈',
          items: [
            '지진이 발생하는 꿈',
            '산사태를 목격하는 꿈',
            '쓰나미가 덮치는 꿈',
            '태풍이 몰아치는 꿈',
            '화산이 폭발하는 꿈',
            '홍수가 발생하는 꿈',
            '폭설이 내리는 꿈',
            '번개가 치는 꿈',
            '폭풍으로 집이 무너지는 꿈',
            '산불이 나는 꿈'
          ]
        }

      ]
    },
    {
      name: '감정',
      icon: '👀',
      subCategories: [
        {
          name: '기쁨을 느끼는 꿈',
          items: [
            '성공을 축하받는 꿈',
            '선물을 받고 기뻐하는 꿈',
            '친구와 함께 웃는 꿈',
            '가족과 기뻐하는 시간을 보내는 꿈',
            '시험에 합격하는 꿈',
            '좋아하는 일을 하는 꿈',
            '사랑하는 사람과 함께 웃는 꿈',
            '오랜 친구와 재회하는 꿈',
            '목표를 달성하는 꿈',
            '생일 파티에서 즐거워하는 꿈'
          ]
        },
        {
          name: '슬픔을 느끼는 꿈',
          items: [
            '친구와 헤어지는 꿈',
            '가족을 잃는 꿈',
            '사랑하는 사람과 이별하는 꿈',
            '일이 실패로 끝나는 꿈',
            '외로움을 느끼는 꿈',
            '눈물을 흘리는 꿈',
            '동물이 죽는 것을 보는 꿈',
            '아픈 친구를 간호하는 꿈',
            '혼자 남겨진 느낌을 받는 꿈',
            '슬픈 소식을 듣는 꿈'
          ]
        },
        {
          name: '화가 나는 꿈',
          items: [
            '누군가와 말다툼하는 꿈',
            '상사에게 질책받는 꿈',
            '친구에게 배신당하는 꿈',
            '가족과 다투는 꿈',
            '계획이 실패하는 꿈',
            '물건을 부수는 꿈',
            '소리를 지르는 꿈',
            '잘못된 상황에 화를 내는 꿈',
            '동료와 갈등을 겪는 꿈',
            '자신에게 실망하며 화를 내는 꿈'
          ]
        },
        {
          name: '두려움을 느끼는 꿈',
          items: [
            '높은 곳에서 떨어지는 꿈',
            '어두운 골목을 지나가는 꿈',
            '귀신이나 괴물에게 쫓기는 꿈',
            '중요한 일을 잊어버리는 꿈',
            '낯선 장소에서 길을 잃는 꿈',
            '불길한 예감을 느끼는 꿈',
            '해를 입을까 두려워하는 꿈',
            '큰 소리나 경고를 듣는 꿈',
            '위험한 동물에게 쫓기는 꿈',
            '비행기 사고를 경험하는 꿈'
          ]
        },
        {
          name: '불안을 느끼는 꿈',
          items: [
            '중요한 시험을 준비하는 꿈',
            '대중 앞에서 발표하는 꿈',
            '소중한 물건을 잃어버리는 꿈',
            '직장에서 실수를 저지르는 꿈',
            '사람들로부터 비난받는 꿈',
            '연인과의 관계가 불안한 꿈',
            '돈을 잃는 꿈',
            '미래에 대해 걱정하는 꿈',
            '상황이 예상대로 풀리지 않는 꿈',
            '중요한 일을 앞두고 긴장하는 꿈'
          ]
        },
        {
          name: '사랑을 느끼는 꿈',
          items: [
            '연인과 행복하게 시간을 보내는 꿈',
            '사랑하는 사람에게 고백받는 꿈',
            '가족과 애정을 나누는 꿈',
            '첫사랑과 재회하는 꿈',
            '연인에게 선물을 받는 꿈',
            '데이트를 즐기는 꿈',
            '결혼을 준비하는 꿈',
            '애정이 가득한 대화를 나누는 꿈',
            '사랑하는 사람을 보호하는 꿈',
            '새로운 사람과 사랑에 빠지는 꿈'
          ]
        },
        {
          name: '분노를 느끼는 꿈',
          items: [
            '누군가에게 화를 내는 꿈',
            '속상한 일을 당하는 꿈',
            '가족과 크게 다투는 꿈',
            '직장에서 부당한 대우를 받는 꿈',
            '친구와 갈등을 겪는 꿈',
            '자신의 실수에 대해 분노하는 꿈',
            '상사에게 크게 화를 내는 꿈',
            '도둑을 잡으려는 꿈',
            '약속이 깨지는 꿈',
            '상황이 잘못되어 화가 나는 꿈'
          ]
        },
        {
          name: '행복을 느끼는 꿈',
          items: [
            '사랑하는 사람과 웃는 꿈',
            '친구와 즐거운 시간을 보내는 꿈',
            '가족과 함께하는 시간에 행복을 느끼는 꿈',
            '목표를 달성하고 기뻐하는 꿈',
            '오랜 꿈을 이루는 꿈',
            '상을 받는 꿈',
            '휴식을 취하면서 평화로움을 느끼는 꿈',
            '동물과 함께 즐거운 시간을 보내는 꿈',
            '아름다운 풍경을 보는 꿈',
            '성공적인 일을 마무리하는 꿈'
          ]
        },
        {
          name: '실망을 느끼는 꿈',
          items: [
            '계획이 실패로 끝나는 꿈',
            '친구에게 배신당하는 꿈',
            '목표를 이루지 못하는 꿈',
            '사랑하는 사람과 헤어지는 꿈',
            '직장에서 성과를 인정받지 못하는 꿈',
            '원하는 것을 얻지 못하는 꿈',
            '시험에 떨어지는 꿈',
            '연인과 다투는 꿈',
            '중요한 기회를 놓치는 꿈',
            '기대했던 일이 어긋나는 꿈'
          ]
        },
        {
          name: '충격을 받는 꿈',
          items: [
            '교통사고를 당하는 꿈',
            '충격적인 소식을 듣는 꿈',
            '갑자기 큰 소리를 듣는 꿈',
            '예상치 못한 사건을 경험하는 꿈',
            '사고 현장을 목격하는 꿈',
            '사랑하는 사람의 변심을 겪는 꿈',
            '친구에게 배신당하는 꿈',
            '예상치 못한 일을 겪는 꿈',
            '집에 무언가가 파손되는 꿈',
            '위험한 상황을 맞닥뜨리는 꿈'
          ]
        }

      ]
    },
    {
      name: '과거',
      icon: '💪',
      subCategories: [
        {
          name: '어린 시절로 돌아가는 꿈',
          items: [
            '어린 시절 집에서 노는 꿈',
            '부모님과 함께 어린 시절을 보내는 꿈',
            '어린 시절 친구와 다시 만나는 꿈',
            '옛날 장난감을 다시 보는 꿈',
            '어린 시절 학교로 돌아가는 꿈',
            '가족과 함께 어린 시절을 회상하는 꿈',
            '과거의 놀이를 다시 즐기는 꿈',
            '어린 시절 음식을 먹는 꿈',
            '동네 친구들과 뛰어노는 꿈',
            '어린 시절 방에서 잠을 자는 꿈'
          ]
        },
        {
          name: '과거의 연인과 관련된 꿈',
          items: [
            '과거 연인과 다시 만나는 꿈',
            '과거 연인과 화해하는 꿈',
            '과거 연인과의 추억을 떠올리는 꿈',
            '과거 연인과 함께 시간을 보내는 꿈',
            '옛 연인과 데이트하는 꿈',
            '옛 연인이 돌아오는 꿈',
            '과거 연인과 다시 사귀는 꿈',
            '과거 연인과 다투는 꿈',
            '옛 연인에게 고백받는 꿈',
            '옛 연인과 다시 헤어지는 꿈'
          ]
        },
        {
          name: '과거의 직장과 관련된 꿈',
          items: [
            '과거 직장 동료를 만나는 꿈',
            '예전 직장으로 다시 출근하는 꿈',
            '과거의 업무를 다시 맡는 꿈',
            '과거 직장에서 상사와 일하는 꿈',
            '과거 직장에서 승진하는 꿈',
            '이전 직장에서 동료들과 협력하는 꿈',
            '과거 직장에서 실수하는 꿈',
            '이전 직장의 분위기를 느끼는 꿈',
            '과거 직장에서 성과를 인정받는 꿈',
            '이전 직장에서 회의를 하는 꿈'
          ]
        },
        {
          name: '과거의 집과 관련된 꿈',
          items: [
            '옛날 집을 다시 방문하는 꿈',
            '어린 시절 집에서 가족과 함께 있는 꿈',
            '과거의 집에서 생활하는 꿈',
            '이전 집을 청소하는 꿈',
            '오래된 집을 개조하는 꿈',
            '과거의 집에서 친구를 만나는 꿈',
            '과거 집에서 혼자 있는 꿈',
            '이전 집에서 이사하는 꿈',
            '옛날 집을 팔거나 구입하는 꿈',
            '옛 집의 이웃을 다시 만나는 꿈'
          ]
        },
        {
          name: '과거의 친구와 관련된 꿈',
          items: [
            '오래된 친구와 다시 만나는 꿈',
            '옛 친구들과 파티를 즐기는 꿈',
            '과거 친구와 함께 시간을 보내는 꿈',
            '친구들과의 추억을 떠올리는 꿈',
            '오랜 친구와 화해하는 꿈',
            '친구들과 함께 여행하는 꿈',
            '과거 친구와 놀이를 즐기는 꿈',
            '친구에게 사과하는 꿈',
            '오랜 친구와 연락이 끊기는 꿈',
            '친구들과 다시 재회하는 꿈'
          ]
        },
        {
          name: '과거의 학교와 관련된 꿈',
          items: [
            '과거 학교에서 수업을 듣는 꿈',
            '학창 시절 친구들과 재회하는 꿈',
            '과거 학교에서 시험을 치르는 꿈',
            '학교에서 선생님을 만나는 꿈',
            '학창 시절로 돌아가는 꿈',
            '학교에서 숙제를 제출하는 꿈',
            '학교에서 운동장을 뛰는 꿈',
            '학교 운동장에서 체육을 하는 꿈',
            '학창 시절의 교복을 입는 꿈',
            '학교에서 동아리 활동을 하는 꿈'
          ]
        },
        {
          name: '과거의 사건을 재경험하는 꿈',
          items: [
            '과거의 실수를 다시 경험하는 꿈',
            '중요한 회의나 발표를 다시 경험하는 꿈',
            '과거 여행에서의 사건을 되새기는 꿈',
            '과거의 중요한 결정을 되돌아보는 꿈',
            '과거에 잃어버린 물건을 다시 찾는 꿈',
            '어린 시절 사고를 다시 겪는 꿈',
            '과거에 있었던 갈등을 다시 겪는 꿈',
            '옛 연인과의 이별을 다시 경험하는 꿈',
            '과거의 직장 문제를 해결하는 꿈',
            '잊고 있던 기억을 떠올리는 꿈'
          ]
        },
        {
          name: '과거의 가족과 관련된 꿈',
          items: [
            '어머니와 함께 어린 시절로 돌아가는 꿈',
            '아버지와 옛날 추억을 회상하는 꿈',
            '형제자매와 어린 시절 놀이를 하는 꿈',
            '조부모님과 시간을 보내는 꿈',
            '가족이 모두 모여 식사를 하는 꿈',
            '과거 가족 여행을 다시 떠나는 꿈',
            '가족과 함께 과거 집에서 사는 꿈',
            '가족 사진을 다시 보는 꿈',
            '가족과 함께 축하하는 꿈',
            '가족이 과거에 겪었던 사건을 되돌아보는 꿈'
          ]
        },
        {
          name: '과거의 여행과 관련된 꿈',
          items: [
            '옛날 여행지를 다시 방문하는 꿈',
            '과거 여행에서의 추억을 떠올리는 꿈',
            '가족과 함께 떠났던 여행을 다시 떠나는 꿈',
            '여행 중에 길을 잃었던 기억을 떠올리는 꿈',
            '친구와 함께 갔던 여행지를 다시 가는 꿈',
            '여행 중에 잃어버린 물건을 찾는 꿈',
            '과거에 방문했던 해외여행을 다시 떠나는 꿈',
            '과거에 있었던 여행 중 사건을 재경험하는 꿈',
            '여행 중에 만난 사람을 다시 만나는 꿈',
            '과거 여행 중 식사를 즐겼던 기억을 떠올리는 꿈'
          ]
        },
        {
          name: '과거의 잘못을 후회하는 꿈',
          items: [
            '과거의 실수를 후회하는 꿈',
            '친구와의 다툼을 후회하는 꿈',
            '중요한 결정을 후회하는 꿈',
            '가족에게 했던 행동을 후회하는 꿈',
            '직장에서의 실수를 되돌아보는 꿈',
            '잃어버린 기회를 후회하는 꿈',
            '옛 연인과의 이별을 후회하는 꿈',
            '과거에 한 행동에 대해 사과하는 꿈',
            '중요한 순간을 놓친 것을 후회하는 꿈',
            '잘못된 선택을 다시 경험하는 꿈'
          ]
        }

      ]
    },
    {
      name: '미래',
      icon: '🤱',
      subCategories: [
        {
          name: '미래의 자신을 보는 꿈',
          items: [
            '나이든 자신을 보는 꿈',
            '성공한 자신과 만나는 꿈',
            '미래에 건강한 자신을 보는 꿈',
            '미래의 자신과 대화하는 꿈',
            '실패한 자신을 마주하는 꿈',
            '미래의 자신이 가족을 돌보는 꿈',
            '더 나은 미래의 자신을 상상하는 꿈',
            '부자가 된 자신을 만나는 꿈',
            '미래에 홀로 지내는 자신을 보는 꿈',
            '다른 모습의 자신을 발견하는 꿈'
          ]
        },
        {
          name: '미래의 직업과 관련된 꿈',
          items: [
            '미래에 승진하는 꿈',
            '새로운 직업을 얻는 꿈',
            '미래의 직장에서 동료와 일하는 꿈',
            '미래에 로봇과 함께 일하는 꿈',
            '직업에서 큰 성과를 내는 꿈',
            '직업에서 실패하는 꿈',
            '미래의 회사에서 일하는 꿈',
            '자신의 회사를 창업하는 꿈',
            '새로운 기술을 배우는 꿈',
            '직업 변화를 경험하는 꿈'
          ]
        },
        {
          name: '미래의 가족과 관련된 꿈',
          items: [
            '미래의 자녀와 시간을 보내는 꿈',
            '결혼 후 미래의 배우자와 함께 있는 꿈',
            '미래의 가족이 성장하는 모습을 보는 꿈',
            '미래에 가족이 함께 행복해하는 꿈',
            '미래에 자녀가 성공하는 꿈',
            '가족 간의 갈등을 해결하는 꿈',
            '미래 가족과 휴가를 떠나는 꿈',
            '미래 가족이 이사하는 꿈',
            '미래에 가족이 병에 걸리는 꿈',
            '미래에 새로운 가족 구성원이 생기는 꿈'
          ]
        },
        {
          name: '미래의 기술을 사용하는 꿈',
          items: [
            '로봇을 사용하는 꿈',
            '미래형 컴퓨터를 사용하는 꿈',
            '가상현실(VR)을 경험하는 꿈',
            '미래의 스마트폰을 사용하는 꿈',
            '인공지능과 대화하는 꿈',
            '자동화된 집에서 생활하는 꿈',
            '미래의 교통수단을 이용하는 꿈',
            '우주에서 생활하는 꿈',
            '미래의 에너지원으로 전기를 사용하는 꿈',
            '무인 자동차를 타는 꿈'
          ]
        },
        {
          name: '미래의 도시에서 사는 꿈',
          items: [
            '고층 빌딩이 가득한 미래의 도시에 사는 꿈',
            '미래의 도시에서 출퇴근하는 꿈',
            '도시의 혼잡한 교통을 경험하는 꿈',
            '깨끗한 공기를 마시며 미래의 도시를 걷는 꿈',
            '친환경 미래 도시를 보는 꿈',
            '미래의 도시에서 쇼핑하는 꿈',
            '미래 도시에서 새로운 사람들을 만나는 꿈',
            '미래 도시에서 식사하는 꿈',
            '미래의 도시를 여행하는 꿈',
            '미래 도시에서의 공공 서비스를 경험하는 꿈'
          ]
        },
        {
          name: '미래의 문제를 해결하는 꿈',
          items: [
            '미래의 기후 문제를 해결하는 꿈',
            '미래 사회의 갈등을 중재하는 꿈',
            '미래의 경제 위기를 해결하는 꿈',
            '과학적 문제를 풀어내는 꿈',
            '미래에 발생할 전염병을 치료하는 꿈',
            '새로운 법을 제정하는 꿈',
            '미래의 전쟁을 막는 꿈',
            '재난 구조 임무를 완수하는 꿈',
            '정치적 갈등을 해결하는 꿈',
            '미래의 교통 문제를 해결하는 꿈'
          ]
        },
        {
          name: '미래의 사회 변화와 관련된 꿈',
          items: [
            '인공지능이 지배하는 사회를 보는 꿈',
            '환경 보호가 최우선이 된 미래 사회를 보는 꿈',
            '모든 인간이 평등한 사회를 경험하는 꿈',
            '미래의 교육 시스템을 체험하는 꿈',
            '새로운 통화 시스템을 사용하는 꿈',
            '미래 사회의 기술 혁명을 경험하는 꿈',
            '로봇이 일상 생활의 일부가 된 사회를 보는 꿈',
            '미래 사회에서 모든 질병이 치료되는 꿈',
            '미래 사회의 법과 질서를 따르는 꿈',
            '미래의 문화와 예술을 체험하는 꿈'
          ]
        },
        {
          name: '미래의 여행과 관련된 꿈',
          items: [
            '우주여행을 떠나는 꿈',
            '미래의 교통수단을 타고 여행하는 꿈',
            '시간 여행을 하는 꿈',
            '가상현실 여행을 경험하는 꿈',
            '미래형 호텔에 머무는 꿈',
            '새로운 행성으로의 여행을 준비하는 꿈',
            '미래의 항공기에서 여행하는 꿈',
            '미래의 에너지로 충전된 차를 타고 여행하는 꿈',
            '초고속 기차로 여행하는 꿈',
            '여행 중에 외계인을 만나는 꿈'
          ]
        },
        {
          name: '미래의 집을 짓는 꿈',
          items: [
            '자동화된 스마트홈을 짓는 꿈',
            '미래형 아파트에 사는 꿈',
            '친환경 에너지를 사용하는 집을 짓는 꿈',
            '우주 공간에 집을 짓는 꿈',
            '미래의 집에서 로봇과 생활하는 꿈',
            '가상현실 장비가 설치된 집을 짓는 꿈',
            '집이 스스로 청소하는 꿈',
            '미래의 집에서 자녀와 함께 생활하는 꿈',
            '미래의 집을 설계하는 꿈',
            '미래형 인테리어로 집을 꾸미는 꿈'
          ]
        },
        {
          name: '미래의 건강과 관련된 꿈',
          items: [
            '미래에 건강 검진을 받는 꿈',
            '미래의 병원에서 치료받는 꿈',
            '미래 의학 기술로 병을 치료하는 꿈',
            '미래의 건강 관련 앱을 사용하는 꿈',
            '유전자 치료로 건강을 유지하는 꿈',
            '미래의 헬스 기구를 사용하는 꿈',
            '미래의 운동 프로그램을 따라하는 꿈',
            '인공지능 의사에게 진료받는 꿈',
            '미래의 건강 관리 시스템을 체험하는 꿈',
            '미래의 의약품을 복용하는 꿈'
          ]
        }

      ]
    },
    {
      name: '인간관계',
      icon: '🤑',
      subCategories: [
        {
          name: '친구와 관련된 꿈',
          items: [
            '오랜 친구와 다시 만나는 꿈',
            '친구와 다투는 꿈',
            '친구와 화해하는 꿈',
            '친구와 여행을 떠나는 꿈',
            '친구에게 도움을 받는 꿈',
            '친구가 배신하는 꿈',
            '친구에게 도움을 주는 꿈',
            '친구의 결혼식을 축하하는 꿈',
            '친구와 함께 식사하는 꿈',
            '친구와 함께 새로운 일을 시작하는 꿈'
          ]
        },
        {
          name: '가족과 관련된 꿈',
          items: [
            '가족과 식사하는 꿈',
            '가족과 여행을 떠나는 꿈',
            '가족이 모두 모이는 꿈',
            '가족과 다투는 꿈',
            '가족과 화해하는 꿈',
            '부모님과 함께 시간을 보내는 꿈',
            '형제자매와 함께 노는 꿈',
            '가족의 결혼을 축하하는 꿈',
            '가족의 생일을 축하하는 꿈',
            '가족이 아프거나 다치는 꿈'
          ]
        },
        {
          name: '연인과 관련된 꿈',
          items: [
            '연인과 데이트하는 꿈',
            '연인과 싸우는 꿈',
            '연인과 화해하는 꿈',
            '연인에게 고백받는 꿈',
            '연인과 함께 여행하는 꿈',
            '연인과 결혼하는 꿈',
            '연인과 이별하는 꿈',
            '연인이 나에게 선물을 주는 꿈',
            '연인과 미래를 약속하는 꿈',
            '연인이 나를 배신하는 꿈'
          ]
        },
        {
          name: '직장 동료와 관련된 꿈',
          items: [
            '동료와 협력하는 꿈',
            '동료와 말다툼하는 꿈',
            '동료와 프로젝트를 성공시키는 꿈',
            '동료와 회의에서 논의하는 꿈',
            '동료와 함께 식사하는 꿈',
            '동료에게 도움을 받는 꿈',
            '동료에게 도움을 주는 꿈',
            '동료의 승진을 축하하는 꿈',
            '동료와 친해지는 꿈',
            '동료와 함께 문제를 해결하는 꿈'
          ]
        },
        {
          name: '모르는 사람과 관련된 꿈',
          items: [
            '모르는 사람과 대화하는 꿈',
            '낯선 사람을 만나는 꿈',
            '모르는 사람이 나를 도와주는 꿈',
            '모르는 사람에게 도움을 주는 꿈',
            '모르는 사람과 갈등을 겪는 꿈',
            '낯선 사람이 나를 위협하는 꿈',
            '모르는 사람과 함께 여행하는 꿈',
            '낯선 사람과 협력하는 꿈',
            '모르는 사람이 나에게 중요한 소식을 전하는 꿈',
            '낯선 사람에게 물건을 받는 꿈'
          ]
        },
        {
          name: '옛 연인과 관련된 꿈',
          items: [
            '옛 연인과 재회하는 꿈',
            '옛 연인과 화해하는 꿈',
            '옛 연인과 다투는 꿈',
            '옛 연인과 다시 사귀는 꿈',
            '옛 연인에게 고백받는 꿈',
            '옛 연인과 함께 식사하는 꿈',
            '옛 연인과 과거를 회상하는 꿈',
            '옛 연인과 새로운 관계를 시작하는 꿈',
            '옛 연인에게서 연락을 받는 꿈',
            '옛 연인과 함께 여행하는 꿈'
          ]
        },
        {
          name: '친척과 관련된 꿈',
          items: [
            '친척을 만나는 꿈',
            '친척의 결혼식에 참석하는 꿈',
            '친척과 다투는 꿈',
            '친척과 화해하는 꿈',
            '친척과 함께 식사하는 꿈',
            '친척에게서 소식을 듣는 꿈',
            '친척이 병에 걸리는 꿈',
            '친척의 집을 방문하는 꿈',
            '친척이 나를 도와주는 꿈',
            '친척과 함께 여행하는 꿈'
          ]
        },
        {
          name: '이웃과 관련된 꿈',
          items: [
            '이웃과 대화하는 꿈',
            '이웃과 다투는 꿈',
            '이웃이 도와주는 꿈',
            '이웃과 함께하는 활동을 하는 꿈',
            '이웃이 이사를 가는 꿈',
            '새로운 이웃을 만나는 꿈',
            '이웃과 친해지는 꿈',
            '이웃과 함께 문제를 해결하는 꿈',
            '이웃에게 도움을 주는 꿈',
            '이웃과 함께 파티에 참석하는 꿈'
          ]
        },
        {
          name: '선생님과 관련된 꿈',
          items: [
            '선생님에게 지도를 받는 꿈',
            '선생님과 대화를 나누는 꿈',
            '선생님에게 칭찬받는 꿈',
            '선생님에게 꾸중을 듣는 꿈',
            '선생님과 함께 공부하는 꿈',
            '과거의 선생님과 재회하는 꿈',
            '선생님에게 조언을 받는 꿈',
            '선생님과 함께 문제를 해결하는 꿈',
            '선생님에게 도움을 받는 꿈',
            '선생님이 나를 격려하는 꿈'
          ]
        },
        {
          name: '사랑과 관련된 꿈',
          items: [
            '연인에게 고백하는 꿈',
            '연인과 결혼하는 꿈',
            '사랑하는 사람과 함께 시간을 보내는 꿈',
            '사랑하는 사람과 다투는 꿈',
            '사랑하는 사람과 화해하는 꿈',
            '사랑을 고백받는 꿈',
            '연인과 함께하는 미래를 꿈꾸는 꿈',
            '사랑하는 사람과 여행을 떠나는 꿈',
            '사랑하는 사람에게 선물을 받는 꿈',
            '사랑하는 사람을 잃는 꿈'
          ]
        }
      ]
    },
    {
      name: '유명인물',
      icon: '💪',
      subCategories: [
        {
          name: '유명인사와 대화하는 꿈',
          items: [
            '유명 배우와 대화하는 꿈',
            '유명 가수와 노래에 대해 이야기하는 꿈',
            '스포츠 스타와 인터뷰하는 꿈',
            '정치인과 정책에 대해 대화하는 꿈',
            '유튜버와 콘텐츠 제작에 대해 대화하는 꿈',
            '유명 작가와 책에 대해 논의하는 꿈',
            'TV 진행자와 토크쇼를 진행하는 꿈',
            '영화 감독과 작품에 대해 토론하는 꿈',
            '유명 셰프와 요리에 대해 이야기하는 꿈',
            '유명 디자이너와 패션에 대해 논의하는 꿈'
          ]
        },
        {
          name: '유명인사와 함께 일하는 꿈',
          items: [
            '유명 배우와 영화 촬영을 하는 꿈',
            '유명 가수와 음반을 작업하는 꿈',
            '유명 축구 선수와 경기를 뛰는 꿈',
            '유명 정치인과 정책 회의를 하는 꿈',
            '유명 기업가와 비즈니스를 논의하는 꿈',
            '유명 작가와 공동 집필하는 꿈',
            '유명 감독과 영화 제작에 참여하는 꿈',
            '유명 디자이너와 패션 프로젝트를 진행하는 꿈',
            '유명 예술가와 전시회를 준비하는 꿈',
            '유명 유튜버와 콜라보 영상을 촬영하는 꿈'
          ]
        },
        {
          name: '유명인사와 사진을 찍는 꿈',
          items: [
            '유명 배우와 셀카를 찍는 꿈',
            '유명 정치인과 공식 사진을 찍는 꿈',
            '유명 가수와 무대에서 사진을 찍는 꿈',
            '유명 운동선수와 트로피를 들고 사진을 찍는 꿈',
            '유명 화가와 미술관에서 사진을 찍는 꿈',
            '유명 디자이너와 패션쇼에서 사진을 찍는 꿈',
            '유명 셰프와 레스토랑에서 사진을 찍는 꿈',
            '유명 코미디언과 웃으며 사진을 찍는 꿈',
            '유명 과학자와 실험실에서 사진을 찍는 꿈',
            '유명 인플루언서와 소셜 미디어에 올릴 사진을 찍는 꿈'
          ]
        },
        {
          name: '유명인사에게 도움을 받는 꿈',
          items: [
            '유명 변호사가 법적 문제를 해결해주는 꿈',
            '유명 의사가 건강 문제를 해결해주는 꿈',
            '유명 투자자가 사업 아이디어에 투자해주는 꿈',
            '유명 스포츠 선수가 훈련을 도와주는 꿈',
            '유명 트레이너가 운동을 가르쳐주는 꿈',
            '유명 요리사가 요리법을 알려주는 꿈',
            '유명 작가가 글쓰기를 가르쳐주는 꿈',
            '유명 가수가 노래 레슨을 해주는 꿈',
            '유명 정치인이 조언을 해주는 꿈',
            '유명 교수에게 학업 지도를 받는 꿈'
          ]
        },
        {
          name: '유명인사와 함께 여행하는 꿈',
          items: [
            '유명 배우와 해외여행을 떠나는 꿈',
            '유명 가수와 콘서트 투어를 가는 꿈',
            '유명 여행가와 세계 일주를 떠나는 꿈',
            '유명 사진작가와 자연 풍경을 촬영하는 꿈',
            '유명 셰프와 미식 여행을 떠나는 꿈',
            '유명 운동선수와 산악 등반을 하는 꿈',
            '유명 역사학자와 역사 유적지를 탐방하는 꿈',
            '유명 인플루언서와 브이로그를 찍으며 여행하는 꿈',
            '유명 과학자와 탐험 여행을 떠나는 꿈',
            '유명 패션 디자이너와 패션 도시를 여행하는 꿈'
          ]
        },
        {
          name: '유명인사에게 조언을 받는 꿈',
          items: [
            '유명 CEO에게 경영 조언을 받는 꿈',
            '유명 작가에게 글쓰기 팁을 받는 꿈',
            '유명 정치인에게 인생 조언을 받는 꿈',
            '유명 트레이너에게 운동 관련 조언을 받는 꿈',
            '유명 가수에게 보컬 트레이닝을 받는 꿈',
            '유명 배우에게 연기 조언을 받는 꿈',
            '유명 셰프에게 요리 기술을 배우는 꿈',
            '유명 교수에게 학업 조언을 받는 꿈',
            '유명 인플루언서에게 소셜 미디어 관리법을 배우는 꿈',
            '유명 디자이너에게 패션 관련 조언을 받는 꿈'
          ]
        },
        {
          name: '유명인사와 공연을 보는 꿈',
          items: [
            '유명 가수와 콘서트에서 노래를 부르는 꿈',
            '유명 배우와 함께 연극을 관람하는 꿈',
            '유명 코미디언과 스탠드업 공연을 보는 꿈',
            '유명 마술사와 마술쇼를 관람하는 꿈',
            '유명 뮤지션과 오케스트라 공연을 즐기는 꿈',
            '유명 무용수와 발레 공연을 보는 꿈',
            '유명 감독과 영화 시사회에 참석하는 꿈',
            '유명 연주자와 클래식 공연을 관람하는 꿈',
            '유명 연극 배우와 무대 뒤에서 공연을 준비하는 꿈',
            '유명 DJ와 클럽에서 공연을 보는 꿈'
          ]
        },
        {
          name: '유명인사와 인터뷰하는 꿈',
          items: [
            '유명 배우와 영화에 대해 인터뷰하는 꿈',
            '유명 가수와 음악 활동에 대해 인터뷰하는 꿈',
            '유명 정치인과 정책을 논의하는 인터뷰를 하는 꿈',
            '유명 유튜버와 콘텐츠 제작에 대해 인터뷰하는 꿈',
            '유명 운동선수와 경기 경험에 대해 이야기하는 꿈',
            '유명 예술가와 작품에 대한 인터뷰를 하는 꿈',
            '유명 CEO와 경영 철학을 인터뷰하는 꿈',
            '유명 교수와 학문적 논의에 대한 인터뷰를 하는 꿈',
            '유명 요리사와 요리법에 대한 인터뷰를 하는 꿈',
            '유명 디자이너와 패션 철학을 논의하는 꿈'
          ]
        },
        {
          name: '유명인사와 파티에 참석하는 꿈',
          items: [
            '유명 배우와 레드카펫 파티에 참석하는 꿈',
            '유명 가수와 뮤직 페스티벌에서 함께 노는 꿈',
            '유명 패션 디자이너의 런칭 파티에 참석하는 꿈',
            '유명 정치인의 기부 행사에 참석하는 꿈',
            '유명 작가와 문학 모임에 참석하는 꿈',
            '유명 요리사와 요리 대회 파티에 참석하는 꿈',
            '유명 스포츠 스타와 승리 파티에 참석하는 꿈',
            '유명 DJ와 클럽에서 파티를 즐기는 꿈',
            '유명 예술가와 미술 전시회 파티에 참석하는 꿈',
            '유명 CEO와 비즈니스 네트워킹 파티에 참석하는 꿈'
          ]
        },
        {
          name: '유명인사를 우연히 만나는 꿈',
          items: [
            '거리에서 유명 배우를 우연히 만나는 꿈',
            '카페에서 유명 가수와 마주치는 꿈',
            '공항에서 유명 정치인을 만나는 꿈',
            '쇼핑몰에서 유명 인플루언서를 만나는 꿈',
            '호텔에서 유명 운동선수를 우연히 마주치는 꿈',
            '해변에서 유명 작가를 만나는 꿈',
            '행사장에서 유명 감독과 우연히 만나는 꿈',
            '공연장에서 유명 예술가를 만나는 꿈',
            '레스토랑에서 유명 셰프와 마주치는 꿈',
            '박물관에서 유명 역사가와 우연히 만나는 꿈'
          ]
        }
      ]
    },
  ]

  private activeTab: string = 'automatic'

  private get indicatorStyle() {
    return {
      transform: this.activeTab === 'automatic' ? 'translateX(0)' : 'translateX(100%)',
    }
  }

  adjustTextareaHeight(): void {
    const textarea = this.$refs.myTextarea as HTMLTextAreaElement;
    if (textarea) {
      textarea.style.height = 'auto'; // 높이를 초기화 후 다시 계산
      const scrollHeight = textarea.scrollHeight; // 높이를 측정
      this.textareaHeight = Math.min(scrollHeight, this.maxHeight); // 최대 높이를 넘지 않도록 제한
    }
  }
  
  private setActiveTab(tab: string) {
    this.activeTab = tab
  }

  onMainCategory(index: number) {
    this.selectedMainCategory = index
    this.showPage = 2
  }

  onSubCategory(index: number) {
    this.selectedSubCategory = index
    this.showPage = 3
  }

  onLastCategory(index: number) {
    this.selectedLastCategory = index

    this.$router.push('/random')
  }

  // 숫자를 포맷팅 (1,000 형태로 표시)
  get formattedCount(): string {
    return this.currentCount.toLocaleString()
  }

  // 카운팅 애니메이션
  private startCounting(): void {
    const duration = 3000
    const steps = 100 // 카운팅 업데이트 횟수 (프레임 수)
    const stepTime = Math.floor(duration / steps) // 각 프레임의 시간 간격 (밀리초)
    const increment = Math.ceil(this.targetCount / steps) // 한 번에 더해질 숫자

    this.intervalId = window.setInterval(() => {
      if (this.currentCount < this.targetCount) {
        this.currentCount += increment
        if (this.currentCount >= this.targetCount) {
          this.currentCount = this.targetCount // 목표값을 초과하지 않도록 설정
          clearInterval(this.intervalId!) // 카운팅이 완료되면 멈춤
        }
      }
    }, stepTime)
  }

  private handleInput(event: Event) {
    const textarea = document.getElementById('textarea') as HTMLTextAreaElement
    const placeholder = document.querySelector('.placeholder') as HTMLDivElement
    
    if (textarea && placeholder) {
      // 텍스트 영역에 입력된 값이 없으면 placeholder를 보여줌
      placeholder.style.display = textarea.value ? 'none' : 'block'
    }

    const target = event.target as HTMLTextAreaElement
    if (target.value.length > 300) {
      target.value = target.value.slice(0, 300)
    }
    this.impression = target.value
  }

  private onPlaceholder() {
    (this.$refs.myTextarea as HTMLTextAreaElement).focus()
  }

  private onConfirm() {
    if (this.impression.length < 10) {
      alert('최소 10글자 이상 작성해주세요.')
    } else {
      this.$router.push('/random')
    }
  }

  async mounted() {
    this.targetCount = await getCounting()
    this.startCounting()
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.8s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

@keyframes slideUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.container {
  padding: 0 20px 20px;
  box-sizing: border-box;
}

.talk {
  margin-top: 20px;
  margin-bottom: 24px;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -0.5px;
  text-align: center;
  color: #fff;
}

.tab-container {
  margin-top: 32px;
  margin-bottom: 20px;
  padding: 4px;
  background-color: #212736;
  border-radius: 100px;
  height: 42px;
}

.tab {
  display: flex;
  border-radius: 100px;
  width: 100%;
  position: relative;
}

.tab-item {
  flex: 1;
  text-align: center;
  padding: 12px 0;
  border-radius: 100px;
  font-size: 14px;
  color: #ffffff80;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  z-index: 1;
}

.tab-indicator {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 50%; /* 탭 인디케이터 너비는 두 개의 탭에 맞춰 50%로 설정 */
  background-color: #ECEEF0;
  border-radius: 100px;
  transition: transform 0.3s ease; /* 슬라이드 트랜지션 */
  z-index: 0; /* 텍스트 뒤에 배경 인디케이터를 배치 */
}

.tab-item.active {
  color: #202223;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
}

.textarea-box {
  padding: 20px;
  border-radius: 16px;
  background-color: #212736;
  position: relative;
}

.custom-textarea {
  padding: 0;
  width: 100%;
  height: 150px;
  max-height: 300px;
  border: none;
  outline: none;
  background-color: #212736;
  color: #b3b3b3;
  resize: none;
  font-size: 15px;
  font-weight: 500;
  line-height: 23px;
  white-space: pre-line;
}

.placeholder {
  position: absolute;
  top: 16px;
  color: #9C9EA0;
  white-space: pre-line;
  font-size: 15px;
  font-weight: 400;
  line-height: 23px;
  text-align: left;
}

.textarea-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  color: #b3b3b3;
  font-size: 14px;
}

.textarea-footer span {
  color: #ECEEF0;
  font-size: 14px;
  font-weight: 400;
}

.textarea-footer span.max {
  color: #737577;
  font-size: 14px;
  font-weight: 400;
}

.main__category {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 8px;
}

.main__category > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1; /* flex-grow로 남은 공간을 채움 */
  width: calc(33.333% - 16px); /* 각 요소의 최대 너비 설정 */
  padding: 16px;
  background-color: #212736;
  border-radius: 16px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;

  opacity: 0;
  transform: translateY(100%);
  animation: slideUp 0.5s forwards;
  box-sizing: border-box;
}

.main__category > div > .category-name {
  margin-top: 12px;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  color: #FFF;
}

.main__category div.selected {
  background-color: #fff;
  color: #202223;
}

.disclamer {
  margin-top: 24px;
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  text-align: center;
  color: #737577;
}

.title {
  margin-top: 20px;
  margin-bottom: 20px;
  color: #fff;
  font-size: 22px;
  font-weight: 700;
}

.title span {
  margin-left: 2px;
}

.sub__category div {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  padding: 20px;
  background-color: #212736;
  border-radius: 16px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;

  opacity: 0;
  transform: translateY(100%);
  animation: slideUp 0.5s forwards;
}

.sub__category div span {
  margin-left: 2px;
}

.sub__category div img {
  margin-left: auto;
}

.sub__category div.selected {
  background-color: #fff;
  color: #202223;
}

.floating {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: calc(100% - 40px);
  padding: 30px;
  background: linear-gradient(180deg, rgba(23, 23, 23, 0) 0%, #171717 15.46%, #171717 82.53%);
}

.floating > button {
  width: 100%;
  min-height: 52px;
  background-color: #ECEEF0;
  padding: 8px 8px;
  border-radius: 24px;
  border-style: none;
  color: #181D23;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  cursor: pointer;
}
</style>